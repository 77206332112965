.App {
  text-align: center;
}

a{
  text-decoration: none;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.customselect {
  /* width: 70px; */
  overflow: hidden;
}

.customselect select {
 width: 100px;
 -moz-appearance: none;
 -webkit-appearance: none;
 appearance: none;
}

.card_main{
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.card_second{
  margin-bottom: 20px;
}
.raper{
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.raper > div{
  margin-right: 10px;
}
.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.header_logo{
  max-width: 250px;
}